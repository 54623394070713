// import './bookings.css'
import { Container, Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import profile from "../../Assets/Images/profile.png";
import lessarrow from "../../Assets/Icons/lessarrow.svg";
import greatarrow from "../../Assets/Icons/arrow.svg";
import Main from "../Main/Main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteConsultation,
  getBookings,
} from "../../Redux/Actions/user/userAll";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  formatConsultationDate,
  formatDate,
  formatDateToDMY,
  getDateDescription,
  getFormattedTime,
  getImageUrl,
  isToday,
  shouldShowContactInfo,
} from "../../Utils/commonFiles";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { startstopLoading } from "../../Redux/Reducers/globalSlice";
import { Encryptedid } from "../../Utils/bcrypt";
import { toast } from "react-toastify";

export default function Bookings() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const viewType = queryParams.get("view_type");
  const Image_url = process.env.REACT_APP_IMG_URL + "public/uploads/";

  const data = useSelector((state) => state?.consultaions?.bookings?.data);

  const limit = 10;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentBooking, setCurrenBooking] = useState(0);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const adjustDate = (days) => {
    if (days < 0 && isToday(currentDate)) return;
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    });
  };

  useEffect(() => {
    let data = {
      page,
      limit,
    };
    if (viewType === "Day") data["date"] = formatDateToDMY(currentDate, true);
    dispatch(startstopLoading(true));
    dispatch(getBookings(data)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, [viewType, currentDate]);

  const handleVideoEventClick = (id) => {
    if (id) {
      navigate(`/consultation/${Encryptedid(id)}`);
    }
  };

  const handleCancelConsultation = () => {
    let id = data?.allBookings[currentBooking]?.consultationId;
    if (id) {
      dispatch(deleteConsultation(id)).then((res) => {
        if (res?.payload?.success) {
          toast.success(res?.payload?.message);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  return (
    <Main>
      <div className="bookings ">
        <Container fluid className="p-0">
          <div className="heading-booking-div">
            <h2 className="booking-h2">Bookings</h2>
          </div>
          <Row className="mt-2 justify-content-between">
            <Col md={2} className="booking calender-set">
              <div
                className={`today-div d-flex justify-content-${
                  viewType === "Day" ? "between" : "center"
                } my-3`}
              >
                {viewType === "Day" && (
                  <div className="arrow-div" onClick={() => adjustDate(-1)}>
                    <img className="" src={lessarrow} alt="Pic not Found!" />
                  </div>
                )}
                <h3 className="text-center">
                  {viewType === "Day"
                    ? `${getDateDescription(currentDate)}`
                    : "All Requests"}
                </h3>
                {viewType === "Day" && (
                  <div className="arrow-div" onClick={() => adjustDate(1)}>
                    <img className="" src={greatarrow} alt="Pic not Found!" />
                  </div>
                )}
              </div>
              <Card className=" booking border-0 list-calender">
                <ListGroup className="">
                  {data?.allBookings?.length ? (
                    data?.allBookings.map((res, index) => (
                      <ListGroup.Item
                        className={`px-0 ${
                          index === currentBooking ? "active-listing" : ""
                        }`}
                        key={index}
                        onClick={() => setCurrenBooking(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="time-set-list">
                          {/* <img
                            className="ppic "
                            src={profile}
                            alt="Pic not Found!"
                          /> */}
                           <img src={res.image ? (Image_url + res.image) : profile} alt="Pic not found!" className='list-image' />

                          <div className="">
                            <p>{res.firstName + " " + res.lastName}</p>
                            <div>
                              <p>
                                <i>
                                  {" "}
                                  {formatConsultationDate(
                                    res?.consultationDate
                                  )}
                                </i>
                              </p>
                              <p>{getFormattedTime(res?.consultationDate)}</p>
                            </div>
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>
                      <Row>
                        <Col md={12}>
                          <p className="text-center">No Clients Found</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                </ListGroup>
                <div className="text-center view-div-set">
                  {viewType === "Day" ? (
                    <Link
                      to="/bookings?view_type=All"
                      className=" text-decoration-underline"
                    >
                      View All Days
                    </Link>
                  ) : (
                    <Link
                      to="/bookings?view_type=Day"
                      className=" text-decoration-underline"
                    >
                      Day View
                    </Link>
                  )}
                </div>
              </Card>
            </Col>
            {data?.allBookings?.length ? (
              <Col md={10} className="bg-white p-2 mt-3 ad-card-prop">
                <div className="main-content d-flex g-3">
                  <div className="img-div p-3">
                    <img
                      className="ppic-lg new-profile-image"
                      src={
                        data?.allBookings[currentBooking]?.image
                          ? getImageUrl(
                              data?.allBookings[currentBooking]?.image
                            )
                          : profile
                      }
                      alt="Pic not Found!"
                    />
                  </div>
                  <div className="maincontent-div px-3 w-100 py-4">
                    <div className="div12 d-flex flex-row-reverse justify-content-between g-3">
                      <div className="name-div1">
                        <p className="call-p text-end fs-6 ">
                          Call Scheduled at{" "}
                          {getFormattedTime(
                            data?.allBookings[currentBooking]?.consultationDate
                          )}{" "}
                          on{" "}
                          {formatDate(
                            data?.allBookings[currentBooking]?.consultationDate
                          )}
                        </p>
                        <button
                          className="cancel-btn float-end bg-transparent border border-0"
                          onClick={handleCancelConsultation}
                        >
                          Cancel Call
                        </button>
                      </div>
                      <div className="name-div2 mt-3">
                        <h5 className="same-properties-heading name-h5 fs-5 text-capitalize">
                          {data?.allBookings[currentBooking]?.firstName +
                            " " +
                            data?.allBookings[currentBooking]?.lastName}
                        </h5>
                        <h5 className="same-properties-heading  urgent-p">
                          Urgent Consultation Request
                        </h5>
                        <h5 className="same-properties-heading pt-2">
                          Leeds LS28
                        </h5>
                        <p className="p1 pt-3">
                          Contact <span className="orange "> URGENTLY</span>{" "}
                        </p>
                        <p className="p1">
                          Earnings{" "}
                          <span className="green">
                            {" "}
                            £{data?.allBookings[currentBooking]?.price}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="detials-div1 d-flex flex-row justify-content-between mt-3">
                      <h5 className=" details-h5 ">Details</h5>

                      <button
                        className="launch-btn float-end border border-0 mb-2 rounded"
                        onClick={() =>
                          handleVideoEventClick(
                            data?.allBookings[currentBooking]?.consultationId
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Launch Call
                      </button>
                    </div>
                    <div className=" mt-3 d-flex justify-content-between">
                      <div className="py-2">
                        <div>
                          <h6 className="booking-h6">
                            {" "}
                            Booking Date:{" "}
                            <span className="span2">
                              {" "}
                              {formatDate(
                                data?.allBookings[currentBooking]
                                  ?.consultationDate
                              )}{" "}
                            </span>{" "}
                          </h6>
                          <h6 className="booking-h6">
                            {" "}
                            Booking Time:{" "}
                            <span className="span2">
                              {" "}
                              {getFormattedTime(
                                data?.allBookings[currentBooking]
                                  ?.consultationDate
                              )}{" "}
                            </span>{" "}
                          </h6>
                        </div>
                        <div className="py-2">
                          <h6 className="booking-h6">
                            {" "}
                            Contact Email:{" "}
                            <span className="span2">
                              {data?.allBookings[currentBooking]?.email}
                            </span>{" "}
                          </h6>

                          {/* <h6 className="booking-h6">
                            Contact Number:
                            <span className=" fw-bold ms-3 ">
                              {data?.allBookings[currentBooking]?.phnNumber}
                            </span>
                          </h6> */}

                          {shouldShowContactInfo(
                            data?.allBookings[currentBooking]?.consultationDate
                          ) ? (
                            <h6 className="booking-h6">
                              Contact Number:
                              <span className="fw-bold ms-3">
                                {data?.allBookings[currentBooking]?.phnNumber}
                              </span>
                            </h6>
                          ) : null}
                        </div>
                      </div>
                      {/* <div
                        className="text-end"
                        onClick={handleShow}
                        style={{ cursor: "pointer" }}
                      >
                        Update
                      </div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Select Date</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Select Time</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <div className="d-flex justify-content-center">
              <Button variant="primary" type="submit" className="launch-btn">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Main>
  );
}
