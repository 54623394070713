import Main from '../Main/Main'
import profile from '../../Assets/Images/profile.png'
import { Table } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getClientDetails } from '../../Redux/Actions/user/userAll'
import '../Account Settings/account_settings.css'
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { formatDateToDMY, getFormattedTime, getImageUrl } from '../../Utils/commonFiles'
import ViewPrescription from '../View Prescription/ViewPrescription'
import { useReactToPrint } from 'react-to-print'

export default function Members() {

  const { id } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const data = useSelector((state) => state.consultaions.clientDetails);

  const PrescriptionRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [viewP, setViewP] = useState();
  const [selectedClient, setSelectedClient] = useState('')
  const [contactInput, setContactInput] = useState({
    recieve_offers: "",
    recieve_confirmation: "",
    recieve_reminders: "",
  });
  const contactPref = useSelector((state) => state.consultaions.contactPref);

  const handleClick = (ques, ans) => {
    setContactInput((prev) => {
      return {
        ...prev,
        [ques]: ans,
      };
    });
  };

  const updateContactPrefs = () => {
    // dispatch(saveContactPref(contactInput));
  };

  const handlePresPrint = useReactToPrint({
    content: () => PrescriptionRef.current,
  });

  const handleClose = () => setShowModal(false)

  useEffect(() => {
    if (contactPref) {
      setContactInput(contactPref);
    }
  }, [contactPref]);

  useEffect(() => {
    dispatch(getClientDetails(id));
  }, [id]);

  return (
    <Main>
      <div className="containe-fluid p-3 ">
        <div className="row ">
          <div className="customer-name d-flex text-center p-0  align-items-center">
            <span className='bg-white ' onClick={() => navigate('/clients')}>&lt;</span>
            <h2 className='ps-4 text-capitalize mb-0'>{data?.fullName}</h2>
          </div>
          <div className="customer-details ad-card-prop bg-white px-0 mt-4">
            <div className='customer-details-div p-3 '>
              <h2 className='customer-details-h2 m-0'>Customer Details</h2>
            </div>
            <div className="details-div">
              <div className="row p-3">
                <div className="col-md-2 col-12">
                  <img className='profile_pic new-profile-image' src={data?.image ? getImageUrl(data?.image) : profile} alt="Pic not Found!" />
                </div>
                <div className=" account_info_ col-md-4 col-12">
                  <h2 >Name</h2>
                  <input className='w-75' placeholder='Simon James' value={data?.fullName} readOnly />
                  <h2 >Email</h2>
                  <input className='w-75' placeholder='simon@simon.com' value={data?.email} readOnly />
                  <h2 >Phone</h2>
                  <input className='w-75' placeholder='+447477000333' value={data?.phnNumber} readOnly />
                </div>
                <div className=" account_info_ col-2">
                  <h2 className=''>Age</h2>
                  <input className='w-50 ' value={data?.age} readOnly />
                </div>
                <div className=" account_info_ col-md-4 col-12">
                  <h2 >Address</h2>
                  <input className='w-75'  value={`${data?.address?.houseNo || 'N/A'}, ${data?.address?.townCity || 'N/A'}`} readOnly />
                  <input className='w-75' value={data?.address?.street || 'N/A'} readOnly />
                  <input className='w-75' value={data?.address?.postCode || 'N/A'} readOnly />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row p-0 py-3'>
          <div className=' col-12 col-lg-5 ps-0  table_scroll display-table'>
            <div className='bg-white table-cell ad-card-prop'>
              <div className=" client-heading-div px-3 py-0 pt-3">
                <h2 className='common-properties-clients'>All Consultations</h2>
              </div>
              <Table className='all_consultations ' responsive>
                <thead className='mb-2 table_head'>
                  <tr className=''>
                    <th className='td_width_issuedate'>Date</th>
                    <th className='td_width_issuedate'>Time</th>
                    <th className='td_width_item'>Doctor</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody className='table_body'>
                  {data?.pastConsultations.length ? data?.pastConsultations.map((res, index) =>
                    <tr key={index}>
                      <div className="table-rows row1 d-flex gap-4">
                        <td className='td'>{formatDateToDMY(res?.date)}</td>
                        <td className='td_width_issuedate'>{getFormattedTime(res?.date)}</td>
                        <td className='td_width_item'>{res?.assignedDoctorName}</td>
                        <td><Link to='#' className=' table_link_ text-decoration-underline text-black'>Start Call</Link></td>
                      </div>
                    </tr>
                  )
                    :
                    <div className="text-center">
                      No Data
                    </div>
                  }
                </tbody>
              </Table>
            </div>
          </div>
          <div className=' col-12 col-lg-7 pe-0 table_scroll scrollbar-hide'>
            <div className='bg-white ad-card-prop'>
              <div className=" client-heading-div px-3 py-0 pt-3 d-flex justify-content-between">
                <h2 className='common-properties-clients'>Prescriptions</h2>
                <Link to={`/prescription/${id}`}>
                  <button type="submit" className=' new-pres'>New Prescription</button>
                </Link>
              </div>
              <Table className='all_consultations ' responsive>
                <thead className='mb-2 table_head'>
                  <tr className=''>
                    <th className='w-25' >Issue Date</th>
                    <th className='td_width_item'>Item</th>
                    <th className='td_width_quantity'>Quantity</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody className='table_body'>
                  {data?.prescriptions?.length ? data?.prescriptions.map((res, index) =>
                    <tr className='' key={index}>
                      <div className="table-rows d-flex gap-4">
                        <td className='td_width_issuedate'>{formatDateToDMY(res?.date)}</td>
                        <td className='td_width_item'>
                          {
                            res.medication.map((medsRes) =>
                              <p>{medsRes?.meds}</p>
                            )
                          }
                        </td>
                        <td className='td_width_quantity'>
                          {
                            res.medication.map((medsRes) =>
                              <p>{medsRes?.quantity}</p>
                            )
                          }
                        </td>
                        <td><Link onClick={() => {
                          setShowModal(true)
                          setViewP(res)
                        }} className=' table_link_ text-decoration-underline text-color '>View</Link></td>
                      </div>
                    </tr>
                  )
                    :
                    <div className="text-center">
                      No Data
                    </div>
                  }
                </tbody>
                {showModal &&
                  <ViewPrescription
                    handleClose={handleClose}
                    viewPresData={viewP}
                    handlePrint={handlePresPrint}
                  />
                }
              </Table>
            </div>
          </div>
        </div>

        <div className="containe-fluid medical_histor p-0 pt-2">
          <div className="col-lg-12 col-12 p-0">
            <div className="desc_area p-0 pb-0 ">
              <div className="row">
                <div className=" col-12 p-0">
                  <div className="new_consultation_booking common_shadow ad-card-prop">
                    <h2 className="common_title padding_36 ">Medical History</h2>
                    <div className="row py-4 px-3 border border-0">
                      <div class="accordion " id="accordionPanelsStayOpenExample ">
                        <div class="accordion-item my-1">
                          <h2 class="accordion-header p-0 border border-0 h6" id="panelsStayOpen-headingOne">
                            <button class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                              Medical History
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="details p-3">
                              {data?.medHistory.length ? data?.medHistory?.map((res, index) =>
                                <div className="d-flex justify-content-between align-items-center py-1" key={index}>
                                  <h6 className='m-0 p-0 w-100'>{res.question}</h6>
                                  <div className="options_part questions_options">
                                    <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleClick("recieve_offers", "Yes")
                                          }
                                          className={
                                            // contactPref
                                            // ? 
                                            res?.answer == "Yes"
                                              ? "select_option_btn  box_shadow selected_color"
                                              : "select_option_btn  box_shadow"
                                            // : "select_option_btn  box_shadow"
                                          }
                                        >
                                          Yes
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleClick("recieve_offers", "No")
                                          }
                                          className={
                                            // contactPref
                                            //   ?
                                            res?.answer == "No"
                                              ? "select_option_btn  box_shadow selected_color"
                                              : "select_option_btn box_shadow"
                                            // : "select_option_btn box_shadow"
                                          }
                                        >
                                          No
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )
                                :
                                <>
                                  <div className="">No Data</div>
                                </>
                              }

                              {/* <div className='text-end p-2 py-3 '>
                                <button className='btn update_btn text-white p-2 '>Update</button>
                              </div> */}

                            </div>
                          </div>
                        </div>
                        {/* <div class="accordion-item my-1">
                          <h2 class="accordion-header p-0 border border-0 " id="panelsStayOpen-headingTwo">
                            <button class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                              Medication
                              <span className="update-history small_para text-black px-3 ">
                                0 items listed
                              </span>
                            </button>
                          </h2>
                          <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="details p-3">
                              <form className="">
                                <div action="" className='d-flex justify-content-between align-items-center '>
                                  <div>
                                    <label className='fs-6 py-3 fw-bold '>Medication Name</label>
                                    <div>
                                      <input type="text" name="" id="" placeholder='write Here...' className='input_  bg-white p-2 rounded w-100' />
                                    </div>
                                  </div>
                                  <div>
                                    <label className='fs-6 py-3 fw-bold '>Dosage</label>
                                    <div>
                                      <input type="text" name="" id="" placeholder='[Optional]' className='input_  bg-white p-2 rounded' />
                                    </div>
                                  </div>
                                </div>
                                <div className='text-end p-2 py-4 d-flex justify-content-end gap-3'>
                                  <button className='btn update_btn text-white p-2 '>Add More</button>
                                  <button className='btn update_btn text-white p-2 '>Save Data</button>
                                </div>
                              </form>

                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </Main >
  )
}