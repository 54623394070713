import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import profile from "../../Assets/Images/profile.png";
import lessarrow from "../../Assets/Icons/lessarrow.svg";
import greatarrow from "../../Assets/Icons/arrow.svg";
import Main from "../Main/Main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getLeads, confirmBooking } from "../../Redux/Actions/user/userAll";
import { Link, useLocation } from "react-router-dom";
import {
  formatConsultationDate,
  formatDate,
  formatDateToDMY,
  getDateDescription,
  getFormattedTime,
  getImageUrl,
  isToday,
  timeAgo,
} from "../../Utils/commonFiles";

export default function Leads() {
  const dispatch = useDispatch();
  const location = useLocation();

  const Image_url = process.env.REACT_APP_IMG_URL + "public/uploads/";
  const data = useSelector((state) => state?.consultaions?.leads?.data);
  const queryParams = new URLSearchParams(location.search);
  const viewType = queryParams.get("view_type");
  const limit = 10;

  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentBooking, setCurrenBooking] = useState(0);

  const adjustDate = (days) => {
    if (days < 0 && isToday(currentDate)) return;
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    });
  };

  const getData = async () => {
    let data = {
      page,
      limit,
    };
    if (viewType === "Day") data["date"] = formatDateToDMY(currentDate, true);
    try {
      dispatch(getLeads(data));
    } catch (error) {}
  };

  const ConfirmBooking = async (id) => {
    try {
      let res = await dispatch(confirmBooking({ id: id })).then(() => {
        setCurrenBooking(0);
        getData();
      });
    } catch {}
  };

  useEffect(() => {
    getData();
  }, [viewType, currentDate]);

  return (
    <Main>
      <div className="leads">
        <Container fluid className="p-0">
          <div className="heading-booking-div">
            <h2 className="booking-h2">New Requests</h2>
          </div>
          <Row className="mt-2 justify-content-between">
            <Col md={2} className="calender-set">
              <div
                className={`today-div d-flex justify-content-${
                  viewType === "Day" ? "between" : "center"
                } my-3`}
              >
                {viewType === "Day" && (
                  <div className="arrow-div" onClick={() => adjustDate(-1)}>
                    <img className="" src={lessarrow} alt="Pic not Found!" />
                  </div>
                )}
                <h3 className="text-center">
                  {viewType === "Day"
                    ? `${getDateDescription(currentDate)}`
                    : "All Requests"}
                </h3>
                {viewType === "Day" && (
                  <div className="arrow-div" onClick={() => adjustDate(1)}>
                    <img className="" src={greatarrow} alt="Pic not Found!" />
                  </div>
                )}
              </div>
              <Card className="border-0 list-calender">
                <ListGroup>
                  {data?.allBookings?.length ? (
                    data?.allBookings.map((res, index) => (
                      <ListGroup.Item
                        style={{ cursor: "pointer" }}
                        className={`px-0 ${
                          index === currentBooking
                           ? 'active-listing' : ""
                        }`}
                        key={index}
                        onClick={() => setCurrenBooking(index)}
                      >
                        <div className="time-set-list">
                          {/* <img
                            className="ppic p-0"
                            src={profile}
                            alt="Pic not Found!"
                          /> */}

                           <img src={res.image ? (Image_url + res.image) : profile} alt="Pic not found!" className='list-image' />

                          <div className="">
                            <p>{res.firstName + " " + res.lastName}</p>
                            <div>
                              <p>
                                <i>
                                  {" "}
                                  {formatConsultationDate(
                                    res?.consultationDate
                                  )}
                                </i>
                              </p>
                              <p>{getFormattedTime(res?.consultationDate)}</p>
                            </div>
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>
                      <Row>
                        <Col md={12}>
                          <p className="text-center">No Clients Found</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                </ListGroup>
                <div className="text-center view-div-set ">
                  {viewType === "Day" ? (
                    <Link
                      to="/leads?view_type=All"
                      className=" text-decoration-underline"
                    >
                      View All Days
                    </Link>
                  ) : (
                    <Link
                      to="/leads?view_type=Day"
                      className=" text-decoration-underline"
                    >
                      Day View
                    </Link>
                  )}
                </div>
              </Card>
            </Col>
            {data?.allBookings?.length ? (
              <Col md={10} className="bg-white p-2 mt-3 ad-card-prop">
                <div className="main-content d-flex g-3">
                  <div className="img-div p-3">
                    <img
                      className="ppic-lg new-profile-image"
                      src={
                        data?.allBookings[currentBooking]?.image
                          ? getImageUrl(
                              data?.allBookings[currentBooking]?.image
                            )
                          : profile
                      }
                      alt="Pic not Found!"
                    />
                  </div>
                  <div className="maincontent-div px-3 w-100 py-4">
                    <div className="div12 d-flex flex-row-reverse justify-content-between g-3">
                      <div className="name-div1">
                        <p className="call-p text-end fs-6 ">
                          {timeAgo(
                            data?.allBookings[currentBooking]?.consultationDate
                          )}
                        </p>
                      </div>
                      <div className="name-div2 mt-3">
                        <h5 className="same-properties-heading name-h5 fs-5 text-capitalize">
                          {data?.allBookings[currentBooking]?.firstName +
                            " " +
                            data?.allBookings[currentBooking]?.lastName}
                        </h5>
                        <h5 className="same-properties-heading  urgent-p">
                          Urgent Consultation Request
                        </h5>
                        <h5 className="same-properties-heading pt-2">
                          Leeds LS28
                        </h5>
                        <p className="p1 pt-3">
                          Contact <span className="orange "> URGENTLY</span>{" "}
                        </p>
                        <p className="p1">
                          Earnings{" "}
                          <span className="green">
                            {" "}
                            £{data?.allBookings[currentBooking]?.price}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="detials-div1 d-flex flex-row justify-content-between mt-3">
                      <h5 className=" details-h5 ">Details</h5>
                    </div>
                    <div className=" mt-3 ">
                      <div className="">
                        <div>
                          <h6 className="booking-h6">
                            {" "}
                            Booking Date:{" "}
                            <span className="span2">
                              {" "}
                              {formatDate(
                                data?.allBookings[currentBooking]
                                  ?.consultationDate
                              )}{" "}
                            </span>{" "}
                          </h6>
                          <h6 className="booking-h6">
                            {" "}
                            Booking Time:{" "}
                            <span className="span2">
                              {" "}
                              {getFormattedTime(
                                data?.allBookings[currentBooking]
                                  ?.consultationDate
                              )}{" "}
                            </span>{" "}
                          </h6>
                        </div>
                        <div className="mt-4 text-left">
                          <button
                            className="d-block launch-btn border border-0 rounded"
                            type="submit"
                            onClick={() =>
                              ConfirmBooking(
                                data?.allBookings[currentBooking]
                                  ?.consultationId
                              )
                            }
                          >
                            Confirm Booking
                          </button>
                        </div>
                        {/* <div>
                        <h6 className='booking-h6'> Contact Email: <span className='span2'>simonjames@email.com </span> </h6>
                        <h6 className='booking-h6'> Contact Number:<span className=' fw-bold ms-3 '>(+44) 7477 202 151 </span> </h6>
                      </div> */}
                      </div>
                      {/* <div className='text-end'>Update</div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
    </Main>
  );
}
