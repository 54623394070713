import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Main from "../Main/Main";
import { useParams } from "react-router-dom";
import { Decryptedid } from "../../Utils/bcrypt";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { startstopLoading } from "../../Redux/Reducers/globalSlice";
import { getConsultationById } from "../../Redux/Actions/user/userAll";
import MomentFunc from "../../Utils/momentDateTime";
import VideoCall from "./VideoCall";
import { io } from "socket.io-client";
import ChatBox from "./ChatBox";
import ConsNotes from "./ConsNotes";

const ConsultationWEBRTC = () => {

    const { id } = useParams();
    const consultantId = id && Decryptedid(atob(id));

    const cookies = new Cookies();
    const BASE_URL = process.env.REACT_APP_HOST_NAME;
    const [socket, setSocket] = useState();
    const socketRef = useRef();

    const [onlineUsers, setOnlineUsers] = useState();

    const [me, setMe] = useState("");
    const dispatch = useDispatch();

    const consultationData = useSelector((state) => state?.consultaions?.consultationDetail);

    useEffect(() => {
        dispatch(startstopLoading(true));
        socketRef.current = io.connect(BASE_URL);
        socketRef.current.emit("video-user-add", "doctor");
        socketRef.current.on("me", (myId) => setMe(myId));
        setSocket(socketRef.current);
        dispatch(getConsultationById(consultantId)).then((res) => {
          if (res?.payload?.success == true || res?.payload?.success == false) {
            dispatch(startstopLoading(false));
          }
        });
        return () => {
            socketRef.current.disconnect();
          };
      }, []);


      useEffect(() => {
        socketRef.current.on("video-users", (users) => {
          setOnlineUsers(users);
        });
      }, [socketRef.current]);



  return (
    <Main>
      <div className="bookings ">
        <Container fluid className="p-0">
        <div id="layout-wrapper" className="bg-dash ">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                 {consultationData && (
                  <div className="col-12">
                    <div className=" cons-top-bar page-title-box d-sm-flex align-items-center justify-content-between">
                      <div>
                        <span className="small_text">Consultation</span>
                        <h4 className="mb-sm-0 font-size-28">
                          Consultation with{" "}
                          <span
                            // onClick={handleUserClick}
                            className="name_color cursor-pointer"
                          >
                            {consultationData?.cons?.user_id?.firstName +
                              " " +
                              consultationData?.cons?.user_id?.lastName}
                          </span>
                        </h4>
                        <div className="mt-2 sub_head p-0">
                          <h4 className="sub-heading-text-header ms-2 ">
                            {MomentFunc.Date(consultationData?.cons?.date)}
                            <span className="">
                              {MomentFunc.Time(consultationData?.cons?.date)}
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div className="page-title-right">
                   
                      </div>
                    </div>
                  </div>
                )} 
                <div className="col-lg-12">
                  <div className="desc_area pb-0">
                    <div className="row">
                      <div className="col-lg-7">
                        {socket && me && consultationData && (
                          <VideoCall
                            me={me}
                            consInfo={consultationData?.cons}
                            socket={socket}
                            onlineUsers={onlineUsers}
                          />
                        )}
                        {consultationData && socket ? (
                          <ChatBox socket={socket} consInfo={consultationData} />
                        ) : null}
                      </div>
                        {/* {consultationData && socket ? (
                          <ConsNotes socket={socket} consInfo={consultationData} />
                         ) : null} */}
             
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </Container>
      </div>
    </Main>
  );
};

export default ConsultationWEBRTC;
