// import '../Medical History/medication_history.css'
import Main from "../Main/Main";
import arrowdown from "../../Assets/Icons/arrowdown.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
// import './personal_info.css'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getPersonalInfo,
  updateProfile,
} from "../../Redux/Actions/user/userAll";
import { Address } from "./Address";
import ContactDetail from "./ContactDetail";

// import MedicalHistory from '../Medical History/MedicalHistory'

export default function PersonalInfo() {
  const userInfo = useSelector((state) => state?.consultaions?.userInfo);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [viewVal, setViewVal] = useState(true);
  var today = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo) {
      setUserData(userInfo);
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getPersonalInfo());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  console.log(userInfo, "userInfo");

  const handleUpdate = () => {
    dispatch(updateProfile({ personalInfo: userData })).then((res) => {
      const personalInfoObject = res.payload.user.data;
      const checkList = {
        phnNumber: "",
        age: "",
        address: "",
      };
      checkList.phnNumber = personalInfoObject?.phnNumber
        ? personalInfoObject?.phnNumber == null
          ? false
          : true
        : false;
      checkList.age = personalInfoObject?.age
        ? personalInfoObject?.age == null
          ? false
          : true
        : false;

      const checkAdd = (obj) => {
        var count = 0;
        for (let key in obj) {
          if (key != "secondLine") {
            if (obj[key] == "") {
              count = count + 1;
            }
          }
        }
        return count >= 1 ? false : true;
      };

      checkList.address =
        Object.keys(personalInfoObject.address).length < 4
          ? false
          : checkAdd(personalInfoObject.address);

      if (checkList.phnNumber && checkList.age && checkList.address) {
        // if (from == "fromnewcons") {
        //   navigate("/new-consultation");
        // }
      }
    });
  };
  const handleClose = () => {
    setViewVal(false);
  };

  return (
    <Main>
      <div className="container medical_history">
        <div className="col-lg-10">
          <div className="desc_area pb-0 ">
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="back-button mb-4">
                  <Link to="/myaccount" className="white-btn">
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-12 p-0">
                <div className="new_consultation_booking common_shadow bg-white ">
                  <h2 className="common_title padding_36">
                    Personal Information
                  </h2>
                  <div className="row py-4 px-3 border border-0">
                    <div
                      class="accordion "
                      id="accordionPanelsStayOpenExample "
                    >
                      <div class="accordion-item my-1">
                        <h2
                          class="accordion-header p-0 border border-0 h6"
                          id="panelsStayOpen-headingOne"
                        >
                          <button
                            class="accordion-button bg-white text-black"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            <h6 className="mb-0"> General</h6>
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingOne"
                        >
                          <div className="details p-3">
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <p className="  m-0 p-0">First Name</p>
                              <div className="btn_">
                                <input
                                  className="white-btn btn_yes_no me-2 btn_personal_info"
                                  onChange={handleChange}
                                  value={userData?.firstName}
                                  name="firstName"
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <p className=" m-0 p-0">Last Name</p>
                              <div className="btn_">
                                <input
                                  className="white-btn btn_yes_no me-2 btn_personal_info"
                                  onChange={handleChange}
                                  value={userData?.lastName}
                                  name="lastName"
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <p className="  m-0 p-0">Age</p>
                              <div className="btn_">
                                <input
                                  className="white-btn btn_yes_no me-2 btn_personal_info"
                                  onChange={handleChange}
                                  value={userData?.age}
                                  max={today}
                                  type="date"
                                  name="age"
                                />
                              </div>
                            </div>
                            <div className="text-end p-2 py-3 ">
                              <button
                                className="btn update_btn text-white p-2 "
                                onClick={handleUpdate}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="accordion-item my-1">
                        <h2 class="accordion-header p-0 border border-0 " id="panelsStayOpen-headingTwo">
                          <button class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            Address
                          </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                          <div className="details p-3">
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <h6 className=' h6 m-0 p-0'>House no/ Name</h6>
                              <div className="btn_">
                                <input className='white-btn btn_yes_no me-2 btn_personal_info' placeholder='32' />

                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <h6 className='h6 m-0 p-0'>Street</h6>
                              <div className="btn_">
                                <input className='white-btn btn_yes_no me-2 btn_personal_info' placeholder='Elthiron Road' />

                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <h6 className=' h6 m-0 p-0'>Second Line (Optional)</h6>
                              <div className="btn_">
                                <input className='white-btn btn_yes_no me-2 btn_personal_info ' placeholder='Fulham' />

                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <h6 className=' h6 m-0 p-0'>Town/City</h6>
                              <div className="btn_">
                                <input className='white-btn btn_yes_no me-2 btn_personal_info' placeholder='London' />

                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <h6 className=' h6 m-0 p-0'>Post Code</h6>
                              <div className="btn_">
                                <input className='white-btn btn_yes_no me-2 btn_personal_info' placeholder='SW6 4BW' />
                              </div>
                            </div>
                            <div className='text-end p-2 py-3 '>
                              <button className='btn update_btn text-white p-2 '>Update</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item my-1">
                        <h2 class="accordion-header p-0 border border-0 " id="panelsStayOpen-headingThree">
                          <button class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            Contact Details
                          </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                          <div className="details p-3">
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <h6 className=' h6 m-0 p-0'>Email Address</h6>
                              <div className="btn_">
                                <input className='white-btn btn_yes_no me-2 btn_personal_info' placeholder='email @email.com' />

                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center align-cotent-center">
                              <h6 className='h6 m-0 p-0'>Phone</h6>
                              <div className="btn_">
                                <input className='white-btn btn_yes_no me-2 btn_personal_info' placeholder='+44747720272' />

                              </div>
                            </div>
                            <div className='text-end p-2 py-3 '>
                              <button className='btn update_btn text-white p-2 '>Update</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <Address userData={userData} />
                      <ContactDetail userData={userData} />
                      {/* <Qualifications userData={userData} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
