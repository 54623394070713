// Doctor side Video Call
import React, { useState, useRef, useEffect } from "react";
import Peer from "simple-peer";
import { toastInfo } from "../../Redux/Reducers/admin/adminPanelSlice";
import moment from "moment/moment";


const VideoCall = ({ me, consInfo, socket, onlineUsers }) => {

  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState("James Pearlman");
  const [call, setCall] = useState({});
  const [userId, setUserId] = useState();

  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  useEffect(() => {
    if (navigator) {
      // navigator?.mediaDevices?.getUserMedia({ video: true, audio: true }).then((currentStream) => {
      //   console.log(currentStream, "345345345")
      //     setStream(currentStream);
      //     myVideo.current.srcObject = currentStream;  
      //   }).catch((error) => {
      //     console.error("Error accessing media devices.", error);
      //     alert("Unable to access camera/microphone. Please check permissions.");
      //   });

  navigator.mediaDevices
  .getUserMedia({ video: true, audio: true })
  .then((currentStream) => {
    console.log("Current Stream: ", currentStream);


    const videoTracks = currentStream.getVideoTracks();
    console.log("Video Tracks: ", videoTracks);


    const audioTracks = currentStream.getAudioTracks();
    console.log("Audio Tracks: ", audioTracks);

    if (videoTracks.length > 0) {
      console.log("Video stream is active");
    } else {
      console.log("No video tracks available");
    }

    if (audioTracks.length > 0) {
      console.log("Audio stream is active");
    } else {
      console.log("No audio tracks available");
    }

    setStream(currentStream);
    myVideo.current.srcObject = currentStream;  
  })
  .catch((error) => {
    console.error("Error accessing media devices:", error);
  });

    }
     // Listen for incoming calls
    socket.on("call_User", ({ from, name: callerName, signal }) => {
      console.log(callerName, "6666666777", signal)

      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });

    socket.on("user-left", () => {
      toastInfo("User left the Call");
      setCallEnded(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    });
// =================================================================
    console.log("object")
    const handleCustomerLate = (res) => {
      toastInfo("Customer is marked late and no refund will be provided")
      console.log(
        "Customer is marked late and no refund will be provided",
        res
      );
      // Additional logic you want to implement
    };
  
    socket.on("customer_late",(data)=> handleCustomerLate(data));
  
    // Clean up the socket listener when component unmounts or socket changes
    // return () => {
    //   socket.off("customer_late", handleCustomerLate);
    // };
// ======================================================================
    return () => {
      window.location.reload();
    };
  }, []);

  useEffect(() => {
    if (userId) {
      const isUser = onlineUsers?.find(
        (item) => item.userId == consInfo?.user_id?._id
      );
    }
  }, [onlineUsers]);

  useEffect(() => {
    if (consInfo && onlineUsers) {
      const socketId = onlineUsers?.find(
        (item) => item.userId == consInfo?.user_id?._id
      )?.socketId;
      if (socketId) {
        console.log(socketId, "233333333")
        setUserId(socketId);
      }
    }
  }, [consInfo, onlineUsers]);


  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    peer.on("error", (err) => {
      console.error("Peer connection error", err);
    });


    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });
    connectionRef.current = peer;
  };

  const leaveCall = () => {
    socket.emit("doctor-disconnected", { to: userId, consultaionId : consInfo?._id });

  fetch("https://toothaid-backend.bosselt.com/api/pay/complete", {
    method: "POST",
    headers: {
      "Content-Type": "application/json", 
    },
    body: JSON.stringify({ consultationId: consInfo?._id }),
  })
    .then(response => response.json())
    .then(data => {
      console.log("Payment complete:", data); 
    })
    .catch(error => {
      console.error("Error completing payment:", error); 
    });

    setCallEnded(true);
    setCallAccepted(false);
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    window.location.reload();
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <button
          onClick={leaveCall}
          className={
            callAccepted && !callEnded
              ? "btn btn-primary white-btn small_white_btn"
              : "btn btn-primary white-btn small_white_btn visibility-hidden"
          }
        >
          Leave Call
        </button>
      </div>
      <div className="card mb-4 video_call_section">
        <div className="pb-0">
          <div className="consulatation_card">
            {callAccepted && !callEnded ? (
              <video
                playsInline
                width="626"
                height="323"
                ref={userVideo}
                autoPlay
                className="full-video"
              />
            ) : (
              <img
                src={require("../../Assets/Images/lg_video_frame.png")}
                alt="live_chat"
                className="img-fluid large_img"
              />
            )}

         
            <img
              src={require("../../Assets/Images/small_video_frame.png")}
              alt="live_chat"
              className="img-fluid video_call_frame"
            />
       
            <video
              playsInline
              muted
              ref={myVideo}
              autoPlay
               className="doctor-video"
            />
        
            {userId && !callAccepted && (
              <div className="start_call">
                <button
                  onClick={() => userId && callUser(userId)}
                  className="cre_new"
                >
                  Start Call
                </button>
              </div>
             )} 
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoCall;
